@import "../sass/variables.scss";
@import "../sass/mixins.scss";
#terms{
    background-color: lighten($color: #000000, $amount: 6);
    padding-left: 15px;
    padding-right: 15px; 
    position: fixed;
    z-index: 78346453;  
    min-width: 100vw; 
    display: grid;
    place-items: center;
    // min-height: 80px;
    width: 100%;
    bottom:0px;
    padding: 0.37em;
    background-color: rgba($color: #0f0f0fd6, $alpha: .8);
    display: grid;
    place-items: center;
    @include mq($xls){
        padding-top: 15px;
        padding-bottom: 15px;
        height: auto;
    }
    .term-text {
        font-family: $open-sans-light;
        font-weight: 300;
        font-size: (18px / $divider);
        line-height: (30px / $divider);
        text-align: left;
        color: #fff;
        max-width: 100%;
        color: #fff;
        margin-right: 20px;
        align-self: center;
        @include mq($lg){
            max-width: 75%;
        }
        @include mq($xls){
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
    .accept-btn{
        min-width: 100px;
        max-height: 45px;
        min-height: 45px;
        display: grid;
        place-items: center;
        background-color: $red;
        border-radius: 9px;
        line-height: 1;
        color:white;
        cursor: pointer;
        @include mq($xls) {
            max-width: 100%;
            min-height: 50px;
            font-size: 20px;
        }
    }
}